.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: 'black';
    font-family: 'GT Maru', cursive!important;
   }
  
  .navbar h1 {
    font-size: 2rem;
    font-family: 'GT Maru', cursive!important;
  }

  .rainbow-text{
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    -webkit-text-fill-color: transparent;
    font-family: 'GT Maru', cursive!important;
  }

  @font-face {
    font-family: 'GT Maru', cursive;
    src: url();
  }
  .navbar .links a {
    margin: 0 10px;
    color: white;
    display: flex;
    text-decoration: none;

  }
  
  .navbar .links a:hover {
    text-decoration: underline;
  }
  